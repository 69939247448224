export default {
  'header.description': 'IT 工具',
  'header.PROJECT_NAME': 'IT 工具',
  'home': 'home',
  'login.logout': '退出登陆',

  Watermark: '加水印',
  Combine: '合并',
  Split: '拆分',
  Tools: '工具集',
  'Add Watermark': '添加水印',
  'Watermark Template': '水印版本',
  Start: '开始',
  'Project Name': '项目名称',
  'Workspace': '工作区域，暂无数据',
  'Note Download Tips': '注：转换完成请及时下载，关闭应用数据清空',
  Files: '文件',
  'Drag and drop multiple files:': '或拖拽多个文件：',
  '(Up to 4 files, each file should be less than 100 MB)': '（最多 4 个文件，单个文件小于 100 MB）',
  '(Up to 4 files, each file should be less than 200 MB)': '（最多 4 个文件，单个文件小于 200 MB）',
  '(Up to 4 files, each file should be less than 1 GB)': '（最多 4 个文件，单个文件小于 1 GB）',
  'file uploaded successfully.': '文件上传成功。',
  'file upload failed.': '文件上传失败。',
  'You can only upload PDF files!': '您只能上传 PDF 文件！',
  'File size cannot exceed 100 MB!': '文件大小不能超过 100 MB！',
  'File size cannot exceed 1 GB!': '文件大小不能超过 1 GB！',
  'Total file size cannot exceed 1 GB!': '文件总大小不能超过 1 GB！',
  'You can only upload up to 4 files.': '最多上传 4 个文件！',
  'Do not add the same file repeatedly!': '不要重复添加相同文件!',
  'Preview': '效果预览',
  'Processing': '待处理',
  'Download': '待下载',
  'Failed': '处理失败',
  'Please select file!': '请选择文件！',
  // 通用
  Delete: '删除',
  Edit: '编辑',
  'No Permission': '没有权限',
  'Server Error': '服务器错误',
};
