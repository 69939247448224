export default {
  'header.description': 'IT Tools',
  'header.PROJECT_NAME': 'IT Tools',
  home: 'home',
  'login.logout': 'Logout',

  'Note Download Tips': 'Note: Please download and close the app promptly once the conversion is done, as the data will be cleared.',
  待处理: 'Processing',
  待下载: 'Download',
  处理失败: 'Failed',
  '请问需要什么资料？': 'What information do you need?',
  '输入文件名、关键词或标签': 'Please enter the file name, keyword or tag',
  '目录浏览': 'Browse catalog',
  '最新资料': 'Latest information',
  '跳转首页': 'Return to home page',
  '下载文件': 'Download file',
  '预览文件': 'Preview file',
  '为你找到': 'Found',
  '相关的': 'files related to',
  '个文件：': ': ',
  '更新于：': 'Updated on ',
  '文件夹内文件内容描述': 'Description of files in the folder',
  // 通用
  Delete: 'Delete',
  Edit: 'Edit',
  'No Permission': 'No Permission',
  'Server Error': 'Server Error',
};
