import renderContent from './renderContent';
import React, {
  // Suspense,
  useState,
  useEffect,
  // lazy,
} from 'react';
import { Layout, Menu, BackTop, Image, Button, Dropdown, FloatButton } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
// import { useKeycloak } from '@react-keycloak/web';
import { useFeishu } from '@/feishu/feishu-context';
import { PrivateRoute } from '../../keycloak/route';
import TopBar from '../head-bar';
import Loading from '../loading';
import { routes } from '../../config/routes';
import './index.less';
import momentaLogo from '../../asset/momenta-logo.svg';
import momentaLogoSmall from '../../asset/momenta-vertical-logo.svg';
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import { getHeadPicture } from '@/pages/basics/user-head-picture';
import { isLocal, isPro } from '@/config/index';
import { useTrans } from '@/hooks/useLanguage';
import { sendPV } from '@/utils/tracking';
import { LoginSwitchComponent, useLogin } from '@/login';

const { SubMenu, Item: MenuItem } = Menu;
const { Sider, Footer } = Layout;

type BasicLayoutProps = {
  changeLang: (value: string) => void;
};
const deep = (routes) => {
  const $t = useTrans();
  const history = useHistory();
  if (routes && routes.length > 0) {
    return routes.map((route) => {
      /**
       * 有翻译读翻译，没有则使用原本输入的
       * 翻译原自：packages/bpm/src/locales/en-US/menu.ts
       */
      const title =
        (route.intlName && <FormattedMessage id={route.intlName} />) ||
        $t(route.name);
      if (route.children && route.children.length > 0) {
        return route.show
          ? (
            <SubMenu
              key={route.key}
              icon={route.icon && <route.icon />}
              title={title}
            >
              {deep(route.children)}
            </SubMenu>
          )
          : null;
      }
      return route.show
        ? (
          <MenuItem
            key={route.key}
            icon={route.icon && <route.icon />}
            onClick={() => history.push(route.path)}
          >
            <Link to={route.path}>{title}</Link>
          </MenuItem>
        )
        : null;
    });
  }
};
// 面包屑

//  侧边栏高亮

const genDefaultKey = () => {
  const prefix = 'menu';
  const { pathname } = window.location;
  const pathList = pathname.split('/').filter((str) => str.length > 0);

  if (pathList.length === 3) {
    return {
      defaultSelectedKeys: [`${prefix}.${pathList[1]}.${pathList[2]}`],
      defaultOpenKeys: [`${prefix}.${pathList[1]}`],
    };
  }
  if (pathList.length === 2) {
    return {
      defaultSelectedKeys: [`${prefix}.${pathList[1]}`],
      defaultOpenKeys: [],
    };
  }
  return {
    defaultSelectedKeys: [],
    defaultOpenKeys: [],
  };
};
// 隐藏侧边栏
const SwitchComponents: React.FC<any> = (props) => {
  sendPV();

  const locale = useIntl();
  const defaultConf = genDefaultKey();
  const [collapsed, setCollapsed] = useState<boolean>(
    (localStorage.getItem('siderCollapsed') || 'false') === 'true', // 默认false, 展开
  );
  const [selectedKeys, setSelectKeys] = useState<Array<any>>([]);
  const [menuData, setMenuData] = useState<Array<any>>(
    collapsed
      ? []
      : routes.map((i) => {
        return `menu.${i.name}`;
      }),
  );

  const headerName = locale.messages['header.description']?.toString() || '';

  const reactLocation = useLocation();

  const pathList = reactLocation.pathname.split('/');
  const pathLength = pathList.length;
  const layoutClass = `${pathList[pathLength - 2]}-${pathList[pathLength - 1]}-layout`;
  const getSelectKeys = () => {
    let menuKey = pathList[pathLength - 1] || '';
    // 短横线转驼峰
    menuKey = menuKey.replace(/-(\w)/g, (_, letter) => letter.toUpperCase());
    if (menuKey) {
      setSelectKeys([`${menuKey}`]);
    }
  };
  useEffect(() => {
    getSelectKeys();
  }, [reactLocation]);

  useEffect(() => {
    if (collapsed === false) {
      setMenuData(routes.map((i) => `${i.key || i.name}`));
    }
  }, [collapsed]);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
    localStorage.setItem('siderCollapsed', collapsed ? 'true' : 'false');
  };

  // TODO 目前 ：当大于1100且收起时，改变尺寸会使侧边栏展开
  const handleResize = () => {
    if (window.innerWidth < 1100) {
      setCollapsed(true);
    } else if (collapsed === true) {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // 销毁
  });

  const { changeLang } = props;
  const { userInfo } = useFeishu();
  const his = useHistory();
  return (
    <div className={`container ${layoutClass}`}>
      {window.innerWidth < 600
        ? <div className='div-mobile-tip'>请移步 PC 端</div>
        : <Layout style={{ height: '100vh' }}>
          <Header>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                color: '#191E28',
              }}
            >
              <div style={{ width: '38px' }}>
                <img src={momentaLogoSmall} alt="" />
              </div>
              <div className='col-dotted-line' />
              <Switch>
                <Route
                  exact
                  path='/'
                >
                  {renderContent()}
                </Route>

                <Route path={'/'}>
                  <Menu
                    triggerSubMenuAction='click'
                    className="layout-menu"
                    mode="horizontal"
                    {...defaultConf}
                    style={{ height: '60px', width: '600px', borderBottom: 0, fontSize: 15 }}
                    // defaultOpenKeys={menuData}
                    selectedKeys={selectedKeys}
                    // openKeys={menuData}
                    onOpenChange={(key) => {
                      setMenuData(key);
                    }}
                  >
                    {deep(routes)}
                  </Menu>
                </Route>
                <Redirect to='/error' />
              </Switch>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {userInfo?.role === 'admin' &&
                <a
                  className="locale-dropdown"
                  style={{ margin: '0 0 0 1em', fontSize: 16 }}
                  onClick={() => his.push('/setting')}>
                  <SettingOutlined />
                </a>
              }
              <span style={{ margin: '0 0 0 1em' }} className="locale-dropdown">
                {getHeadPicture(userInfo?.avatar)}
                {userInfo?.name}
              </span>
              {/* 隐藏国际化 */}
              <div style={{ marginLeft: 10, display: 'block' }}>
                {locale.locale === 'en'
                  ? (
                    <Button
                      className="iconfont-icon-lang"
                      onClick={() => {
                        changeLang('zh-CN');
                      }}
                    >中</Button>
                  )
                  : (
                    <Button
                      className="iconfont-icon-lang"
                      onClick={() => {
                        changeLang('en-US');
                      }}
                    >En</Button>
                  )}
              </div>
              {/* <Dropdown
              overlayStyle={{ width: '160px' }}
              overlay={
                <Menu
                  onClick={() => {
                    // keycloak.logout();
                    localStorage.removeItem('token');
                  }}
                >
                  <Menu.Item key="logout">
                    <FormattedMessage id="login.logout" />
                  </Menu.Item>
                </Menu>
              }
            >
              <a style={{ margin: '0 0 0 2em' }} className="locale-dropdown">
                {userInfo?.name}
              </a>
            </Dropdown> */}
            </div>
            {/* <BackTop /> */}
            <FloatButton.BackTop />
          </Header>
          <Layout className={`site-layout ${collapsed ? 'collapsed' : ''}`}>
            {renderContent()}
          </Layout>
        </Layout>
      }
    </div>
  );
};
// 控制登陆
const BasicLayout: React.FC<BasicLayoutProps> = (
  props: BasicLayoutProps,
): JSX.Element => {
  const { initialized } = useLogin();
  if (!isLocal && !initialized) {
    return <Loading />;
  }

  const ENABLE_KEYCLOAK_ROUTE_CHECK = !(
    process.env.REACT_APP_DISABLE_KEYCLOAK_ROUTE_CHECK === 'true'
  );

  return (
    <Router>
      {/* {ENABLE_KEYCLOAK_ROUTE_CHECK ? (
        <PrivateRoute {...props} path='/' component={SwitchComponents} />
      ) : (
        <SwitchComponents {...props} />
      )} */}
      <LoginSwitchComponent {...props} path="/" SwitchComponents={SwitchComponents} />
    </Router>
  );
};

export default BasicLayout;
