import { FeishuProvider, useFeishu } from '@/feishu/feishu-context';
import type { IUserInfo } from '@it-fe/feishu/src/typing';

import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import keycloak from '@/keycloak/config';
import { PrivateRoute } from '@/keycloak/route';
import { feishuConfig } from '@/feishu/config';
import { isLocal } from '@/config';

// 可以通过 sessionStorage 控制 isFeishuLogin。
const isFeishuLoginByStore = window.sessionStorage.getItem('isFeishuLogin');
// sessionStorage.setItem('isFeishuLogin', false)
// Boolean('false') === true
const defaultFeishuLogin = true; // 默认使用飞书登录，默认为 true
const isFeishuLogin = isFeishuLoginByStore != null ? isFeishuLoginByStore === 'true' : defaultFeishuLogin;
console.info('当前 isFeishuLogin', isFeishuLogin);

interface ILoginProviderProps {
  children?: React.ReactNode;
}
interface IContext {
  userInfo: {
    name: string;
  } | IUserInfo;
  initialized: boolean;
  logout: () => void;
  keycloak?: {} & any;
}

interface kcloak {
  [key: string]: any;
}

export const useLogin = (): IContext => {
  if (isFeishuLogin) {
    return useFeishu();
  }
  const { keycloak, initialized } = useKeycloak();
  const { idTokenParsed } = keycloak as kcloak;

  const userInfo = {
    name: idTokenParsed?.preferred_username,
    ...keycloak,
  };
  return {
    initialized,
    userInfo,
    logout: keycloak.logout,
    keycloak,
  };
};

export const LoginProvider: React.FC<ILoginProviderProps> = (props: ILoginProviderProps) => {
  const { children } = props;

  if (isFeishuLogin) {
    // 使用飞书登录。
    if (isLocal) {
      // 本地默认跳过登录
      return <>{children}</>;
    }
    return (
      <FeishuProvider
        authClient={feishuConfig}
      >
        {children}
      </FeishuProvider>
    );
  }

  const eventLogger = (event: unknown, error: unknown) => {
    console.debug('onKeycloakEvent', event, error);
  };

  const tokenLogger = (tokens: any) => {
    localStorage.setItem('token', tokens.token);
  };

  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: 'check-sso' }}
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

interface ISwitchProps {
  SwitchComponents: React.ComponentType<any>;
}
export const LoginSwitchComponent: React.FC<ISwitchProps & any> = (props: ISwitchProps & any) => {
  const { SwitchComponents, ...mainProps } = props;

  const ENABLE_KEYCLOAK_ROUTE_CHECK = !(
    process.env.REACT_APP_DISABLE_KEYCLOAK_ROUTE_CHECK === 'true'
  );

  if (ENABLE_KEYCLOAK_ROUTE_CHECK && !isFeishuLogin) {
    return <PrivateRoute {...mainProps} path="/" component={SwitchComponents} />;
  }
  return (
    <SwitchComponents {...mainProps} />
  );
};
