import axios from '@/utils/axios';
// import { serviceHost } from '@/environment';
import {
  ILoginResponse,
  IAppIdResponse,
  IConfigParametersRequest,
  IConfigParametersResponse,
  ICallbackRequest,
  ICallbackResponse,
} from '@it-fe/feishu/src/typing';

export const checkIfLogin = (): Promise<ILoginResponse> => {
  return axios
    .get('/lark/auth/is_login')
    .then((res) => res.data);
};

export const fetchAppId = (): Promise<IAppIdResponse> => {
  return axios
    .get('/lark/auth/app_id')
    .then((res) => res.data);
};

export const fetchConfigParameters = (params: IConfigParametersRequest): Promise<IConfigParametersResponse> => {
  return axios
    .get('/lark/auth/config_param', { params })
    .then((res) => res.data);
};

export const fetchCallback = (params: ICallbackRequest): Promise<ICallbackResponse> => {
  return axios
    .get('/lark/auth/callback', { params })
    .then((res) => res?.data?.data || res.data);
};
