import { isLocal } from '@/config';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
if (!isLocal) {
  Sentry.init({
    dsn: 'https://5c833f5ecac14445be7f5d6777b16e2a@sentry.mmtwork.com/49',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
