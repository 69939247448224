import React, { FC, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import BasicLayout from './components/layout';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import langMap, { LangConf } from './locales';
import { getLang, storeLang } from './utils/lang';
import './main.less';
import '@/utils/sentry';
import { isPro } from '@/config/index';
import { isDev } from './config';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import { LoginProvider } from './login';
dayjs.extend(relativeTime);

function showSystemInfo() {
  const { __GIT_DESCRIBE__, __BUILD_TIME__ } = window?.preEnv || {
    // vite 会把 preEnv.__GIT_DESCRIBE__ 变成设置的变量。
    __GIT_DESCRIBE__: preEnv.__GIT_DESCRIBE__,
    __BUILD_TIME__: preEnv.__BUILD_TIME__,
  };
  console.info('[GIT_DESCRIBE]', __GIT_DESCRIBE__);
  console.info('[BUILD_TIME]', dayjs(__BUILD_TIME__).format('YYYY-MM-DD HH:mm:ss'));
  console.info(`From Now: ${dayjs(__BUILD_TIME__).fromNow()}. Typeof: ${typeof __BUILD_TIME__}`);
  console.info('NOW:', dayjs().format('YYYY-MM-DD HH:mm:ss'), 'NODE_ENV:', process.env.NODE_ENV);
  console.info('REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT);

  if (isDev) {
    console.info('REACT_APP_API_VERSION', process.env.REACT_APP_API_VERSION);
    console.info('REACT_APP_HOST', process.env.REACT_APP_HOST);
    console.info('REACT_APP_DISABLE_KEYCLOAK_ROUTE_CHECK', process.env.REACT_APP_DISABLE_KEYCLOAK_ROUTE_CHECK);
    console.info('REACT_APP_KEYCLOAK_URL', process.env.REACT_APP_KEYCLOAK_URL);
    console.info('REACT_APP_KEYCLOAK_REALM', process.env.REACT_APP_KEYCLOAK_REALM);
    console.info('REACT_APP_KEYCLOAK_CLIENT_ID', process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  }
}
showSystemInfo();

const getAntdLocale = (locale: string) => {
  const antdLocaleMap = {
    en: enUS,
    zh: zhCN,
  };
  return antdLocaleMap[locale as keyof typeof antdLocaleMap] || enUS;
};

const App: FC = () => {
  const [lang, setLang] = useState(getLang());

  const changeLang = (value: string): void => {
    setLang(value);
    storeLang(value);
  };

  const messages: LangConf = langMap[lang] ? langMap[lang] : langMap['en-US'];
  // moment.locale(messages.locale);
  const antdLocale = getAntdLocale(messages.locale);

  useEffect(() => {
    const titleFormat: string = messages.locale === 'en' ? 'IT Tools' : 'IT 工具';
    document.title = titleFormat;
  }, []);

  return (
    <LoginProvider>
      <IntlProvider messages={messages} locale={messages.locale}>
        <ConfigProvider locale={antdLocale}>
          <BasicLayout changeLang={changeLang} />
        </ConfigProvider>
      </IntlProvider>
    </LoginProvider>
  );
};

export default App;
