import { lazy } from 'react';
import {
  DesktopOutlined,
  SketchOutlined,
  // ToolOutlined,
} from '@ant-design/icons';
import { isLocal, isDev } from './index';

interface IMetaItem {
  key?: string;
}

export type RouteItem = {
  name: string;
  intlName?: string; // 使用翻译的 name
  key: string;
  meta?: string[] | IMetaItem[]; // Breadcrumb 节点
  show?: boolean; // 是否在侧边栏展示
  icon?: any; // 一级导航需要，二级不需要
  path?: string;
  component?: React.LazyExoticComponent<React.FC<any>>;
  children?: RouteItem[];
};

export const routes: RouteItem[] = [
  {
    name: 'home',
    key: 'home',
    show: false,
    path: '/',
    component: lazy(() => import('../pages/home')),
  },
  {
    name: 'Tools',
    key: 'tools',
    show: true,
    children: [
      {
        name: 'PDF Watermark',
        key: 'pdfWatermark',
        path: '/tools/watermark',
        show: false,
        component: lazy(() => import('../pages/tools')),
      },
      {
        name: 'BD Search',
        key: 'bdSearch',
        path: '/tools',
        show: false,
        component: lazy(() => import('../pages/tools')),
      },
      {
        name: 'BD Search Result',
        key: 'searchResult',
        path: '/tools/bd-search-result',
        show: false,
        component: lazy(() => import('../pages/tools')),
      },
      {
        name: 'BD Resource',
        key: 'bdResource',
        path: '/tools/bd-resource',
        show: false,
        component: lazy(() => import('../pages/tools')),
      },
    ],
  },
  {
    name: 'admin setting',
    key: 'setting',
    path: '/setting',
    show: false,
    component: lazy(() => import('../pages/setting')),
  },
  {
    name: 'demo',
    icon: SketchOutlined,
    key: 'demo',
    path: '/demo',
    // meta: [],
    show: !!isLocal,
    component: lazy(() => import('../pages/demo')),
  },
  {
    name: 'demo2',
    icon: SketchOutlined,
    key: 'demo2',
    path: '/demo2',
    // meta: [],
    show: !!isLocal,
    component: lazy(() => import('../pages/demo')),
  },
  {
    name: 'Demo Home',
    icon: DesktopOutlined,
    show: !!isLocal,
    key: 'DemoHome',
    children: [
      {
        name: 'beta schema form',
        key: 'betaSchemaFormSimple',
        path: '/beta-schema-form-simple',
        // meta: [],
        show: true,
        component: lazy(() => import('../pages/demo/beta-schema-form-simple')),
      },
      {
        name: 'pro table',
        key: 'proTableSimple',
        path: '/pro-table-simple',
        // meta: [],
        show: true,
        component: lazy(() => import('../pages/demo/pro-table-simple')),
      },
    ],
  },
];
